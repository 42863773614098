import { useGlobalContext } from "src/context/useGlobalContext";

const NavBar = () => {

    const { navigateTo } = useGlobalContext();
    
    return (
        <div className={`flex sm:justify-center sticky top-0 bg-black z-50`}>
            <div className="w-full max-w-[1280px] flex justify-between px-4">
                <img className="w-28 cursor-pointer" src="icons/david-chavez-logo.svg" alt="" onClick={() => navigateTo('', '', '/')}/>
                <button className="py-6" onClick={() => navigateTo('show-contact-me-modal', 'true')}>Contact me</button>
            </div>
        </div>
    )
};

export default NavBar;
