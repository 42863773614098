import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "src/context/useGlobalContext";

import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import ContactMeModal from "../contactMeModal/ContactMeModal";
import MangoSite from "../mangoSite/MangoSite";

const Home = ({ ANIMATION_TRANSITION_DELAY }) => {
	const WELCOME_TEXT_ANIMATION_DURATION = 500;
	const PAGE_SPEED = 0.4;
	const MOBILE_PAGE_SPEED = 0.3;
	const ANIMATION_TRANSITION_DURATION = 500;

	const { isMobile, navigateTo, documentLoaded_ref } = useGlobalContext();

	const [hideWelcomeMessage, SetHideWelcomeMessage] = useState(
		documentLoaded_ref.current
	);
	const [removeWelcomeMessage, SetRemoveWelcomeMessage] = useState(
		documentLoaded_ref.current
	);
	const [ name, SetName ] = useState('');
	const [ email, SetEmail ] = useState('');
	const [ phoneNumber, SetPhoneNumber ] = useState('');
	const [ message, SetMessage ] = useState('');
	const [ messageSent, SetMessageSent ] = useState(false);
	const [ loading, SetLoading ] = useState(false);
	const [ errorMessage, SetErrorMessage ] = useState('');

	const timer_ref = useRef(new Date().getTime());

	// const componentHasRendered_ref = useRef(false);

	const handleSubmit = async () => {
		try {
			if(messageSent || loading) return;
			if(!name || !email || !phoneNumber || !message) {
				SetErrorMessage('Please fill out all fields')
				return;
			};

			const elapsedTime = new Date().getTime() - timer_ref.current;
			if(elapsedTime < 10 * 1000) {
				SetMessageSent(true);
				return;
			};

			SetLoading(true);
			SetErrorMessage('');

			const response = await fetch('https://davidchavez.tech/message-client', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					// 'Authorization': `Bearer: `
				}, 
				body: JSON.stringify({
					name,
					email,
					phoneNumber,
					message,
				})
			});

			SetLoading(false);
			SetMessageSent(true);

			return response;
		} catch (err) {
			SetLoading(false);
			SetErrorMessage('Error');
			throw new Error(err.message)
		}
	}

	useEffect(() => {
		if(name && email && phoneNumber && message ) {
			SetErrorMessage('');
		}
	}, [name, email, phoneNumber, message])

	useEffect(() => {

		if(!documentLoaded_ref.current) {
			setTimeout(() => {
				SetHideWelcomeMessage(true);
			}, ANIMATION_TRANSITION_DELAY);
			setTimeout(() => {
				SetRemoveWelcomeMessage(true);
			}, ANIMATION_TRANSITION_DELAY + WELCOME_TEXT_ANIMATION_DURATION);
		}

		// if(!componentHasRendered_ref.current) {
		// 	componentHasRendered_ref.current = true;
		// 	return
		// };

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("show");
				} else {
					entry.target.classList.remove("show");
				}
			});
		});

		document.querySelector('body').style.overflowY = 'hidden';

		setTimeout(() => {
			const textElements = document.querySelectorAll(".text-animate");
			const imageElements = document.querySelectorAll(".image-zoom");
			
			textElements.forEach((element) => observer.observe(element));
			imageElements.forEach((element) => observer.observe(element));
		},  ANIMATION_TRANSITION_DELAY + ANIMATION_TRANSITION_DURATION);

		return () => {
			document.querySelector('body').style.overflowY = '';
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={`welcome-text flex fixed w-screen h-screen left-0 top-0 justify-center items-center z-10 text-4xl ${
					removeWelcomeMessage ? "hidden" : ""
				} ${hideWelcomeMessage ? "hide" : ""}`}
			>Welcome</div>
			<Parallax
				pages={isMobile ? 7.5 : 6}
				className="no-scrollbar-mobile flex flex-col items-start sm:items-center"
			>
				<ParallaxLayer
					className="flex flex-col items-start sm:items-center"
					offset={0}
					speed={isMobile ? MOBILE_PAGE_SPEED : PAGE_SPEED}
				>
					<div className="max-w-[1280px] relative">
						<img
							className="w-screen lg:w-auto"
							src={
								isMobile
									? "/images/hero-banner_mobile.png"
									: "/images/hero-banner_pc.png"
							}
							alt=""
						/>
						<div className="absolute left-8 top-16 text-5xl lg:text-6xl leading-tight text-animate">
							DAVID
							<br />
							CHAVEZ
						</div>
						<div className="absolute left-8 top-56 text-sm lg:text-base text-animate">
							Let's get your app idea
							<br />
							to fruition
						</div>
					</div>
				</ParallaxLayer>
				<ParallaxLayer offset={1}
					speed={isMobile ? MOBILE_PAGE_SPEED : PAGE_SPEED}>
					<div className="no-scrollbar-mobile flex flex-col items-start sm:items-center mb-10">
						<div className="text-5xl lg:text-6xl leading-tight text-animate mb-6 ml-4 max-w-[1280px]">
							BEAUTIFUL  AND PROFESSIONAL
							<br />
							UI DESIGN
						</div>
					</div>
					<MangoSite />
				</ParallaxLayer>

				{/* COMMUNICATION section */}
				<ParallaxLayer
					className="flex flex-col items-start sm:items-center"
					offset={isMobile ? 2 : 2}
					speed={isMobile ? MOBILE_PAGE_SPEED : PAGE_SPEED}
				>
					<div className="max-w-[1280px] my-36">
						<div className="pl-1 text-5xl lg:text-8xl lg:pl-0 text-animate">
							COMMUNICATION
						</div>
						<div className="pl-1 text-lg lg:text-4xl lg:pl-0 text-animate">
							IS KEY WHEN IT COMES TO WORKING TOGETHER
						</div>

						<div className="flex flex-col lg:flex-row lg:gap-x-80 mt-10 text-animate">
							<div>
								<div className="mt-20 pl-4 lg:pl-0 text-gray-400 ">
									Keeping an open line of communication
								</div>
								<div className="flex flex-col py-6 pl-6 lg:pl-4">
									<span>- My personal phone number</span>
									<span>- Text messaging</span>
									<span>- Google meet</span>
									<span>- Teams</span>
									<span>- e-mail</span>
								</div>
								<div className="mt text-gray-400 pl-4 lg:pl-0">
									You will also get access to your own
									dashboard <br />
									to check on the status of your project at
									any time
								</div>
							</div>
							<div className="mt-8 lg:mt-0">
								<img
									src="/images/project-status-update_pc.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</ParallaxLayer>

				{/* Portfolio section */}
				<ParallaxLayer
					className="flex flex-col items-start sm:items-center"
					offset={3}
					speed={isMobile ? MOBILE_PAGE_SPEED : PAGE_SPEED}
				>
					<div className="max-w-[1280px] flex flex-wrap justify-center xl:justify-start cursor-pointer">
						<div
							onClick={() => navigateTo("id", "1", "portfolio")}
							className="overflow-hidden image-zoom"
						>
							<img
								src="images/portfolio-thumbnail-1.jpg"
								alt=""
							/>
						</div>
						<div
							onClick={() => navigateTo("id", "2", "portfolio")}
							className="overflow-hidden image-zoom"
						>
							<img
								src="images/portfolio-thumbnail-2.jpg"
								alt=""
							/>
						</div>
						<div
							onClick={() => navigateTo("id", "3", "portfolio")}
							className="overflow-hidden image-zoom"
						>
							<img
								src="images/portfolio-thumbnail-3.jpg"
								alt=""
							/>
						</div>
						<div
							onClick={() => navigateTo("id", "4", "portfolio")}
							className="overflow-hidden image-zoom"
						>
							<img
								src="images/portfolio-thumbnail-4.jpg"
								alt=""
							/>
						</div>
					</div>
				</ParallaxLayer>

				<ParallaxLayer
					className="flex flex-col items-start sm:items-center"
					offset={isMobile ? 4.5 : 4}
					speed={isMobile ? MOBILE_PAGE_SPEED : PAGE_SPEED}
				>
					{/* PAGES I'VE WORKED ON section */}
					<div className="max-w-[1280px] w-full mb-40 lg:mb-0">
						<div className="my-12 text-center text-2xl text-animate">
							Pages I’ve worked on
						</div>
					</div>
					{/* LOGOS section */}
					<div className="w-full max-w-[1280px] bg-white flex justify-evenly py-5 flex-wrap gap-x-4 lg:gap-x-0 mb-96 text-animate">
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://shop.hasbro.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-1.jpg"
								alt=""
							/>
						</a>
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://hyperx.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-2.jpg"
								alt=""
							/>
						</a>
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://www.ufcstore.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-3.jpg"
								alt=""
							/>
						</a>
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://dockers.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-4.jpg"
								alt=""
							/>
						</a>
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://www.goodamerican.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-5.jpg"
								alt=""
							/>
						</a>
						<a
							className="flex w-[100px] lg:w-auto"
							href="https://www.dormify.com/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="self-center"
								src="images/brand-6.jpg"
								alt=""
							/>
						</a>
					</div>

					{/* CONTACT ME section */}
					<div className="w-full max-w-[1280px] flex flex-col-reverse lg:flex-col gap-y-[150px] lg:gap-y-0">
						<div className="w-full max-w-[1280px] py-20 flex flex-col lg:flex-row justify-center items-center mb-0 lg:mb-96 text-animate">
							<div className="w-80 border-r-0 lg:border-r border-gray-700 flex flex-col items-center mb-16 lg:mb-0">
								<div className="flex flex-col items-center h-32">
									<img
										className="w-9 mb-8"
										src="icons/pens.svg"
										alt=""
									/>
									<div className="text-10">Designer</div>
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-gray-400 text-center">
									Eye to detail, value clean design patterns,
									<br />
									and thoughtful interactions.
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-center">
									<div className="text-center mb-5">
										Things I enjoy designing:
									</div>
									<div className="text-gray-400">
										UX, UI, Web, Mobile, Apps, Logos
									</div>
								</div>
								<div className="flex flex-col items-center h-36 text-sm text-center">
									<div className="text-center mb-5">
										Design tools:
									</div>
									<div className="text-gray-400">
										Figma
										<br />
										Photoshop
										<br />
										Illustrator
										<br />
										After effects
									</div>
								</div>
							</div>
							<div className="w-80 border-r-0 lg:border-r border-gray-700 flex flex-col items-center mb-16 lg:mb-0">
								<div className="flex flex-col items-center h-32">
									<img
										className="w-9 mb-8"
										src="icons/frontend.svg"
										alt=""
									/>
									<div className="text-10">
										Front-end Developer
									</div>
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-gray-400 text-center">
									I like to code from scratch, and
									<br />
									enjoy bringing ideas to life.
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-center">
									<div className="text-center mb-5">
										Languages:
									</div>
									<div className="text-gray-400">
										HTML, CSS, SASS, PHP, Javascript,
										<br />
										React, Typescript, git
									</div>
								</div>
								<div className="flex flex-col items-center h-36 text-sm text-center">
									<div className="text-center mb-5">
										Dev Tools:
									</div>
									<div className="text-gray-400">
										VS studio code
										<br />
										autohotkey
										<br />
										Github
										<br />
										Terminal
										<br />
										CodePen
									</div>
								</div>
							</div>
							<div className="w-80 flex flex-col items-center">
								<div className="flex flex-col items-center h-32">
									<img
										className="w-9 mb-8"
										src="icons/backend.svg"
										alt=""
									/>
									<div className="text-10">
										Backend developer
									</div>
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-gray-400 text-center lg:w-56">
									Handling a server with the latest security
									practices allows me to completely create
									applications with full functionality
								</div>
								<div className="flex flex-col items-center h-24 text-sm text-center">
									<div className="text-center mb-5">
										Languages:
									</div>
									<div className="text-gray-400">
										NodeJS, Express, MongoDB, Linux, git
									</div>
								</div>
								<div className="flex flex-col items-center h-36 text-sm text-center">
									<div className="text-center mb-5">
										Dev Tools:
									</div>
									<div className="text-gray-400">
										VS studio code
										<br />
										autohotkey
										<br />
										Github
										<br />
										Terminal
									</div>
								</div>
							</div>
						</div>

						<div className="w-full max-w-[1280px] flex flex-col items-center">
							<div className="w-full max-w-[1280px] pb-10 flex justify-center text-4xl text-animate">
								Contact me
							</div>
							<div className="w-full lg:w-[700px] px-6 mb-10 flex flex-col lg:flex-row justify-between gap-y-3 lg:gap-x-8 text-animate">
								<div className="flex flex-col w-full lg:w-auto justify-between gap-y-3">
									<input
										className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
										type="text"
										placeholder="Name"
										value={name}
										onChange={e => SetName(e.currentTarget.value)}
									/>
									<input
										className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
										type="text"
										placeholder="Email"
										value={email}
										onChange={e => SetEmail(e.currentTarget.value)}
									/>
									<input
										className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
										type="text"
										placeholder="Phone number"
										value={phoneNumber}
										onChange={e => SetPhoneNumber(e.currentTarget.value)}
									/>
									{!isMobile && (
										<div className="h-[34px]"></div>
									)}
								</div>

								<div className="lg:w-[445px] flex flex-col">
									<textarea
										className="bg-transparent border-b border-gray-400 flex-shrink-0 mb-4 py-2 px-2"
										rows="6"
										placeholder="Message"
										value={message}
										onChange={e => SetMessage(e.currentTarget.value)}
									></textarea>
									<button className={`${!errorMessage ? 'bg-orange-500' : 'bg-red-800'} py-1 rounded flex justify-center ${loading ? 'bg-orange-950' : ''}`} onClick={handleSubmit}>
										{loading ? <span className="loader" /> : ''}{errorMessage ? errorMessage : messageSent ? 'Message sent!' : 'Send message'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</ParallaxLayer>
			</Parallax>

            <ContactMeModal />
		</>
	);
};

export default Home;
