
import { useRef } from "react";
import { useGlobalContext } from "src/context/useGlobalContext";
import ContactMeModal from "../contactMeModal/ContactMeModal";

const PortfolioPage = () => {

    const { getQueryParameter } = useGlobalContext();
    const id_ref = useRef(getQueryParameter('id'));

    const data = {
        1: {
            title: 'Juise Raw',
            text: `Juise Raw is a dynamic and user-friendly e-commerce platform designed to sell a wide variety of bottled juices online. The website combines a visually appealing interface with robust functionality to provide customers with a seamless shopping experience.
            
            Key Features:
            
            Responsive Design: Ensured compatibility and optimal user experience across all devices, including desktops, tablets, and smartphones.
            Product Catalog: Displayed a diverse range of bottled juices with detailed descriptions, ingredients, and nutritional information.
            Search and Filter: Implemented advanced search and filter options to help users quickly find their preferred products.
            Shopping Cart: Developed a user-friendly shopping cart with the ability to add, remove, and modify product quantities.
            Secure Checkout: Integrated secure payment gateways to handle transactions safely and efficiently.
            Admin Dashboard: Built a comprehensive admin dashboard for managing products, processing orders, and monitoring sales analytics.
            Customer Reviews: Included a feature for customers to leave reviews and ratings for products.
            SEO Optimization: Implemented SEO best practices to improve the website’s visibility on search engines.
            
            Challenges Overcome:
            
            Optimized the site’s performance to handle high traffic during peak hours.
            Ensured data security and compliance with industry standards for online transactions.
            Implemented an intuitive user interface to enhance the customer shopping experience.
            Outcome:
            The Juise Raw online store successfully launched and received positive feedback for its ease of use, speed, and reliable performance. It significantly boosted the client’s online presence and increased their sales revenue.
            
            This project demonstrates my ability to develop and deploy comprehensive e-commerce solutions from scratch, showcasing my skills in both frontend and backend development, as well as my attention to detail in creating a secure and user-friendly platform.`,
        },
        2: {
            title: 'BotaniCare',
            text: `BotaniCare is a comprehensive mobile application designed to provide indoor plant enthusiasts with the tools and knowledge they need to care for their plants effectively. The app offers personalized tips, watering schedules, and a unique toolkit to help users understand and meet the needs of their green companions.

            Key Features:
            
            Personalized Plant Care: Customized care plans for each plant based on species, size, and environmental conditions.
            Watering Schedule: Intelligent watering schedules that send reminders to users when their plants need hydration.
            Plant Database: Extensive library of indoor plants with detailed care instructions, tips, and troubleshooting guides.
            Health Monitoring: Features for tracking plant health and identifying common issues such as pests and diseases.
            Notification System: Push notifications to remind users about watering, feeding, and other plant care tasks.
            User Profiles: Allows users to create profiles for each of their plants, including photos, notes, and care history.
            Community Forum: A space for users to share experiences, ask questions, and connect with other plant enthusiasts.
            Tool Kit: Includes tools like a light meter, humidity monitor, and soil moisture sensor integration to provide accurate care recommendations.
            Visual Recognition: Plant identification feature using AI-powered image recognition.
            In-App Purchases: Options to buy plant care products, tools, and accessories directly through the app.
            Technologies Used:
            
            As the lead developer, I was responsible for both the frontend and backend development of the BotaniCare mobile app. My role included designing the user interface, implementing core features, integrating third-party APIs, and ensuring the app’s performance and security.
            
            Challenges Overcome:
            
            Developed an intuitive and visually appealing user interface to enhance user engagement.
            Integrated real-time data synchronization to ensure users receive timely updates and notifications.
            Implemented machine learning algorithms for accurate plant identification and personalized care recommendations.
            Outcome:
            The BotaniCare app successfully launched with a growing user base and positive feedback for its comprehensive features and user-friendly design. It has helped many indoor plant enthusiasts maintain healthier plants and enjoy their plant care routines.
            
            This project highlights my ability to develop innovative mobile applications that combine advanced technology with user-centric design, showcasing my skills in full-stack development and my passion for creating tools that enhance everyday life.`,
        },
        3: {
            title: 'Yoco Beauty store',
            text: `Yoco Beauty Store is a vibrant and sophisticated mobile application designed to offer a seamless shopping experience for a wide range of beauty products. The app provides users with access to skincare, makeup, haircare, and wellness products, all within an easy-to-navigate interface.

            Key Features:
            
            Product Catalog: Comprehensive listings of beauty products with detailed descriptions, ingredients, usage instructions, and customer reviews.
            Advanced Search: Powerful search functionality with filters for category, brand, price range, and customer ratings.
            Personalized Recommendations: Used data from other users with similar interests.
            Shopping Cart: User-friendly shopping cart with the ability to add, remove, and modify product quantities.
            Secure Checkout: Integrated secure payment gateways to handle transactions safely and efficiently.
            User Accounts: Allow customers to create profiles, view order history, and manage wish lists.
            Push Notifications: Notifications for new arrivals, promotions, and order status updates.
            Admin Dashboard: Comprehensive admin dashboard for managing products, processing orders, and monitoring sales analytics.
            Promotions and Discounts: Ability to create and manage promotional campaigns, discount codes, and special offers.
            Customer Reviews: Feature for customers to leave reviews and ratings for products.
            SEO Optimization: Implemented SEO best practices to improve the app’s visibility in app stores.
            Technologies Used:
            
            As the lead developer, I was responsible for the full-stack development of the Yoco Beauty Store mobile app. My role included designing the user interface, implementing backend services, integrating third-party APIs, and ensuring the overall performance and security of the platform.
            
            Challenges Overcome:
            
            Developed an intuitive and visually appealing user interface to enhance the shopping experience.
            Implemented advanced filtering and search capabilities to help users find products easily.
            Ensured the app’s performance could handle high traffic during promotional periods.
            Integrated secure payment processing to protect customer data and transactions.
            Outcome:
            The Yoco Beauty Store mobile app successfully launched with a rapidly growing user base and positive reviews for its ease of use and comprehensive features. The app significantly boosted the client's online presence and sales revenue.
            
            This project demonstrates my ability to develop feature-rich mobile applications that meet complex user needs, showcasing my expertise in full-stack development and my commitment to creating secure, user-friendly digital solutions.
            `,
        },
        4: {
            title: 'Buildrent',
            text: `BuildRent is an innovative mobile application designed to simplify the process of renting or buying properties. The app provides users with a seamless experience for searching, viewing, and managing real estate listings, whether they are looking to rent an apartment or purchase a home.

            Key Features:
            
            Property Listings: Comprehensive database of properties for rent and sale, complete with photos, descriptions, and detailed information.
            Advanced Search: Powerful search functionality with filters for location, price, property type, amenities, and more.
            Map Integration: Interactive map view to help users locate properties in their desired areas.
            Favorites: Ability for users to save and compare their favorite listings.
            Notifications: Push notifications for new listings that match user preferences and for important updates on their saved properties.
            User Profiles: Profiles for renters, buyers, and property owners, including personal details and property preferences.
            In-App Messaging: Secure messaging system for direct communication between buyers/renters and property owners/agents.
            Booking Appointments: Functionality to schedule property viewings and book appointments with real estate agents.
            Payment Integration: Secure payment options for rental deposits and purchase transactions.
            Reviews and Ratings: User reviews and ratings for properties and real estate agents.
            Technologies Used:
            
            As the lead developer, I was responsible for the end-to-end development of the BuildRent mobile app. My responsibilities included designing the user interface, implementing backend services, integrating various APIs, and ensuring the app’s security and performance.
            
            Challenges Overcome:
            
            Developed a user-friendly interface to enhance the property search experience.
            Integrated advanced filtering and search capabilities to allow users to find properties that meet their exact criteria.
            
            Outcome:
            The BuildRent app successfully launched with a rapidly growing user base and positive reviews for its ease of use and comprehensive features. It has become a valuable tool for individuals looking to rent or buy properties, as well as for real estate agents and property owners looking to list their properties.
            
            This project demonstrates my ability to develop feature-rich mobile applications that meet complex user needs, showcasing my expertise in full-stack development and my commitment to creating seamless, secure, and user-centric digital solutions.`,
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col">
                <img src={`images/portfolio-${id_ref.current}-image-1.jpg`} alt="" />
                <img src={`images/portfolio-${id_ref.current}-image-2.jpg`} alt="" />
            </div>
            <div className="p-5 bg-gray-900 pb-8">
                <div className="text-4xl mb-4">{data[id_ref.current]?.title}</div>
                <div className="text-lg text-gray-500 whitespace-pre-line max-w-[800px]">
                    {data[id_ref.current]?.text}
                </div>
            </div>
            <ContactMeModal />
        </div>
    )
};

export default PortfolioPage;
