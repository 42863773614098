
import { useEffect, useRef, useState } from 'react';
import './MangoSlide.scss';

const MangoSlide = ({pageCover, PAGE_COVER_DELAY, id, slidesPositions, handleSlideChange}) => {

    const BACKGROUND_DELAY = 400;
    
    const [ showBackground, SetShowBackground ] = useState('');
    const [ showBottle, SetShowBottle ] = useState('');

    const hasLoaded_ref = useRef(false);

    useEffect(() => {
        if(pageCover) return;
        if (!hasLoaded_ref.current) {
            setTimeout(() => {
                hasLoaded_ref.current = true;
                SetShowBottle('show-bottle');
                if(slidesPositions[id] === 'center') SetShowBackground('show-background');
            }, PAGE_COVER_DELAY / 2);
        } else if (slidesPositions[id] === 'center') {
            setTimeout(() => SetShowBackground('show-background'), BACKGROUND_DELAY);
        } else SetShowBackground('');
        
    }, [pageCover, slidesPositions]);


    return (
        <div className={`slide cursor-pointer flex justify-center items-center w-[600px] ${slidesPositions[id]}`} onClick={() => handleSlideChange(id)} >
            <img className={`raw-mango-filled max-w-[90vw] absolute z-[-1] slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/raw-mango-filled.svg" alt="" />
            <img className={`paint-yellow-right ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-yellow-right.png" alt="" />
            <img className={`paint-yellow-left ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-yellow-left.png" alt="" />
            <img className={`leaf-one absolute slide-down ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/leaf-1.png" alt="" />
            <img className={`leaf-two absolute slide-down ${showBackground}`} style={{'--delay': '.2s'}} src="images/pages/juise-raw/leaf-2.png" alt="" />
            <img className={`cut-mango absolute z-[-1] slide-up ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/cut-mango.png" alt="" />
            <img className={`slice-1 absolute z-[-1] rotate-in-to-left ${showBackground}`} style={{'--delay': '0s', '--rotation': '-40deg'}} src="images/pages/juise-raw/mango-slice.png" alt="" />
            <img className={`slice-2 absolute z-[-1] rotate-in-to-left ${showBackground}`} style={{'--delay': '.1s', '--rotation': '-10deg'}} src="images/pages/juise-raw/mango-slice.png" alt="" />
            <img className={`slice-3 absolute z-[-1] rotate-in-to-left ${showBackground}`} style={{'--delay': '.2s', '--rotation': '0deg'}} src="images/pages/juise-raw/mango-slice.png" alt="" />
            <img className={`bottle zoom-out ${showBottle} ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/mango.png" alt="" />
            <img className={`raw-mango-empty max-w-[90vw] absolute slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/raw-mango-empty.svg" alt="" />
        </div>
    )
};

export default MangoSlide;
