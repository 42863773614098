import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "src/context/useGlobalContext";

const ContactMeModal = () => {
	
	const { navigate, isMobile, getQueryParameter } = useGlobalContext();

	const [show, SetShow] = useState(false);
	const [showContactMeModal, SetShowContactMeModal] = useState(false);
	const [ name, SetName ] = useState('');
	const [ email, SetEmail ] = useState('');
	const [ phoneNumber, SetPhoneNumber ] = useState('');
	const [ message, SetMessage ] = useState('');
	const [ messageSent, SetMessageSent ] = useState(false);
	const [ loading, SetLoading ] = useState(false);
	const [ errorMessage, SetErrorMessage ] = useState('');

	const timer_ref = useRef(new Date().getTime());

	const showContactMeModal_inPath = getQueryParameter('show-contact-me-modal');

	const handleSubmit = async () => {
		try {
			if(messageSent || loading) return;
			if(!name || !email || !phoneNumber || !message) {
				SetErrorMessage('Please fill out all fields')
				return;
			};
			
			const elapsedTime = new Date().getTime() - timer_ref.current;
			if(elapsedTime < 10 * 1000) {
				SetMessageSent(true);
				return;
			};

			SetLoading(true);
			SetErrorMessage('');

			const response = await fetch('https://davidchavez.tech/message-client', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					// 'Authorization': `Bearer: `
				}, 
				body: JSON.stringify({
					name,
					email,
					phoneNumber,
					message,
				})
			});

			SetLoading(false);
			SetMessageSent(true);

			return response;
		} catch (err) {
			SetLoading(false);
			SetErrorMessage('Error');
			throw new Error(err.message)
		}
	};

	useEffect(() => {
		if(name && email && phoneNumber && message ) {
			SetErrorMessage('');
		}
	}, [name, email, phoneNumber, message])

	useEffect(() => {
        if(showContactMeModal_inPath) SetShowContactMeModal(true);
        else setTimeout(() => SetShowContactMeModal(false), 200);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showContactMeModal_inPath])

	useEffect(() => {
		if (showContactMeModal_inPath) setTimeout(() => SetShow(true), 100);
		else SetShow(false);
	}, [showContactMeModal_inPath]);

	return showContactMeModal ? (
		<div
			className={`contact-me-modal ${show ? "show" : ""}`}
			data-type="modal"
		>
			<div className="container p-2 w-[80vw] lg:px-10 lg:py-5 lg:w-min">
				<div className="w-full max-w-[1280px] pb-10 flex justify-center text-4xl">
					Contact me
				</div>
				<div className="w-full lg:w-[700px] mb-10 flex flex-col lg:flex-row justify-between gap-y-3 lg:gap-x-8">
					<div className="flex flex-col w-full lg:w-auto justify-between gap-y-3">
						<input
							className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
							type="text"
							placeholder="Name"
							value={name}
							onChange={e => SetName(e.currentTarget.value)}
						/>
						<input
							className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
							type="email"
							placeholder="Email"
							value={email}
							onChange={e => SetEmail(e.currentTarget.value)}
						/>
						<input
							className="px-1 bg-transparent border-b w-full lg:w-[280px] border-gray-400 py-2"
							type="text"
							placeholder="Phone number"
							value={phoneNumber}
							onChange={e => SetPhoneNumber(e.currentTarget.value)}
						/>
						{!isMobile && <div className="h-[34px]"></div>}
					</div>

					<div className="lg:w-[445px] flex flex-col">
						<textarea
							className="bg-transparent border-b border-gray-400 flex-shrink-0 mb-4 py-2 px-2"
							rows="6"
							placeholder="Message"
							value={message}
							onChange={e => SetMessage(e.currentTarget.value)}
						></textarea>
						<button className={`${!errorMessage ? 'bg-orange-500' : 'bg-red-800'} py-1 rounded flex justify-center ${loading ? 'bg-orange-950' : ''}`} onClick={handleSubmit}>
							{loading ? <span className="loader" /> : ''}{errorMessage ? errorMessage : messageSent ? 'Message sent!' : 'Send message'}
						</button>
					</div>
				</div>
				<div className="w-full flex justify-end">
					<button
						className=""
						onClick={(e) => navigate(-1)}
					>
						Cerrar
					</button>
				</div>
			</div>
		</div>
	) : '';
};

export default ContactMeModal;
