
import { useEffect, useRef, useState } from 'react';
import './RedSlide.scss';

const RedSlide = ({pageCover, PAGE_COVER_DELAY, id, slidesPositions, handleSlideChange}) => {

    const BACKGROUND_DELAY = 400;
    
    const [ showBackground, SetShowBackground ] = useState('');
    const [ showBottle, SetShowBottle ] = useState('');

    const hasLoaded_ref = useRef(false);

    useEffect(() => {
        if(pageCover) return;
        if (!hasLoaded_ref.current) {
            setTimeout(() => {
                hasLoaded_ref.current = true;
                SetShowBottle('show-bottle');
                if(slidesPositions[id] === 'center') SetShowBackground('show-background');
            }, PAGE_COVER_DELAY / 2);
        } else if (slidesPositions[id] === 'center') {
            setTimeout(() => SetShowBackground('show-background'), BACKGROUND_DELAY);
        } else SetShowBackground('');
        
    }, [pageCover, slidesPositions]);


    return (
        <div className={`slide cursor-pointer flex justify-center items-center w-[600px] ${slidesPositions[id]}`} onClick={() => handleSlideChange(id)} >
            <img className={`raw-mango-filled max-w-[90vw] absolute z-[-1] slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/life-fruit-filled.svg" alt="" />
            <img className={`paint-red-right ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-red-right.png" alt="" />
            <img className={`paint-red-left ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-red-left.png" alt="" />
            <img className={`leaf-one absolute slide-down ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/leaf-1.png" alt="" />
            <img className={`leaf-two absolute slide-down ${showBackground}`} style={{'--delay': '.2s'}} src="images/pages/juise-raw/leaf-2.png" alt="" />
            <img className={`banana-leaf-1 absolute z-[-1] rotate-in-to-right ${showBackground}`} style={{'--delay': '0s', '--rotation': '0deg'}} src="images/pages/juise-raw/banana-leaf-1.png" alt="" />
            <img className={`banana-leaf-3 absolute z-[-1] rotate-in-to-right ${showBackground}`} style={{'--delay': '0s', '--rotation': '0deg'}} src="images/pages/juise-raw/banana-leaf-3.png" alt="" />
            <img className={`banana-leaf-2 absolute z-[-1] rotate-in-to-right ${showBackground}`} style={{'--delay': '0s', '--rotation': '0deg'}} src="images/pages/juise-raw/banana-leaf-2.png" alt="" />
            <img className={`banana-1 absolute z-[-1] slide-down ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/sole-banana-1.png" alt="" />
            <img className={`banana-2 absolute z-[-1] slide-down ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/sole-banana-2.png" alt="" />
            <img className={`bottle zoom-out ${showBottle} ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/red-bottle.png" alt="" />
            <img className={`raw-mango-empty max-w-[90vw] absolute slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/life-fruit-empty.svg" alt="" />
        </div>
    )
};

export default RedSlide;
