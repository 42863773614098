const PageTransition = ({ show }) => {
	
	return (
		<div
			className={`page-loader fixed top-0 w-screen h-screen ${
				show ? "loading" : ""
			}`}
			style={{ backgroundColor: "#141414" }}
		/>
	);
};

export default PageTransition;
