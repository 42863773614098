
const MangoSiteLogic = ({props}) => {
    return (
        <div className="">
            
        </div>
    )
};

export default MangoSiteLogic;
