
import { useState } from "react";

import { useGlobalContext } from "src/context/useGlobalContext";
import MangoSlide from "./components/mango/MangoSlide";
import RedSlide from "./components/red/RedSlide";
import SugarCaneSlide from "./components/sugarCaneSlide/SugarCaneSlide";

import './Slider.scss';

const Slider = ({pageCover, PAGE_COVER_DELAY, handleSelectedSlide}) => {

    const { isMobile } = useGlobalContext();

    const [ slidesPositions, SetSlidesPosition ] = useState({
        0: 'left',
        1: 'center',
        2: 'right',
    });
    const [ slideIsChanging, SetSlideIsChanging ] = useState('');

    const handleSlideChange = (id) => {
        if (id === 0) SetSlidesPosition({
            0: 'center',
            1: 'right',
            2: 'left',
        });
        else if (id === 1) SetSlidesPosition({
            0: 'left',
            1: 'center',
            2: 'right',
        });
        else SetSlidesPosition({
            0: 'right',
            1: 'left',
            2: 'center',
        });

        handleSelectedSlide(id);

        setTimeout(() => SetSlideIsChanging('slide-is-changing'), 100);
        setTimeout(() => SetSlideIsChanging(''), 200);
    }

    const shiftSlide = direction => {
        console.log(direction)
        const _slidesPosition = JSON.parse(JSON.stringify(slidesPositions));
        if(direction === 'right') {
            _slidesPosition[0] = slidesPositions[2];
            _slidesPosition[1] = slidesPositions[0];
            _slidesPosition[2] = slidesPositions[1];

            SetSlidesPosition(_slidesPosition);
        } else {
            _slidesPosition[0] = slidesPositions[1];
            _slidesPosition[1] = slidesPositions[2];
            _slidesPosition[2] = slidesPositions[0];

            SetSlidesPosition(_slidesPosition);
        }
console.log(Object.entries(_slidesPosition))
        const centerSlideId = Object.entries(_slidesPosition).find(([_, value]) => value === 'center')[0];
        console.log(Number(centerSlideId))
        handleSelectedSlide(Number(centerSlideId))
    }
    
    return (
        <div className="slider flex justify-center lg:my-14 relative h-full">
            <img className={`circle ${slideIsChanging}`} src='images/pages/juise-raw/circle.svg' alt="" />
            <RedSlide pageCover={pageCover} PAGE_COVER_DELAY={PAGE_COVER_DELAY} id={0} slidesPositions={slidesPositions} handleSlideChange={handleSlideChange} />
            <MangoSlide pageCover={pageCover} PAGE_COVER_DELAY={PAGE_COVER_DELAY} id={1} slidesPositions={slidesPositions} handleSlideChange={handleSlideChange} />
            <SugarCaneSlide pageCover={pageCover} PAGE_COVER_DELAY={PAGE_COVER_DELAY} id={2} slidesPositions={slidesPositions} handleSlideChange={handleSlideChange} />
            {
                isMobile && <>
                    <div id="left" className="absolute left-0 w-20 h-[inherit] flex justify-center" onClick={() => shiftSlide('left')}>
                        <img src="icons/arrow-tall--left.svg" className="w-12" alt="" />
                    </div>
                    <div id="right" className="absolute right-0 w-20 h-[inherit] flex justify-center" onClick={() => shiftSlide('right')}>
                        <img src="icons/arrow-tall--right.svg" className="w-12" alt="" />
                    </div>
                </>
            }
        </div>
    )
};

export default Slider;
