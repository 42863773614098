// import { Route, Routes} from 'react-router-dom';
import { GlobalContextProvider, useGlobalContext } from './context/useGlobalContext';

import NavBar from './components/navBar/NavBar';
import Home from './components/home/Home';
import PortfolioPage from './components/portfolioPage/PortfolioPage';
import MyProjectStatus from './components/myProjectStatus/MyProjectStatus';
import MangoSite from './components/mangoSite/MangoSite';

import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import PageTransition from './components/pageTransition/PageTransition';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
	<GlobalContextProvider>
		<App />
	</GlobalContextProvider>
)

function App() {

	const { navigate, beginTransition, ANIMATION_TRANSITION_DELAY } = useGlobalContext();

	useEffect(() => {
		const goBackOnClick = (e) => {
            if(e.target.dataset.type === 'modal') navigate(-1);
        }

        window.addEventListener('click', goBackOnClick);

        return () => window.removeEventListener('click', goBackOnClick);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return <>
		<PageTransition show={beginTransition} delay={ANIMATION_TRANSITION_DELAY} />
		<NavBar />

		<Routes>
			<Route path="/" element={<Home ANIMATION_TRANSITION_DELAY={ANIMATION_TRANSITION_DELAY} />} />
			<Route path="/portfolio" element={<PortfolioPage  />} />
			<Route path="/my-project-status" element={<MyProjectStatus  />} />
			<Route path="/juise-raw" element={<MangoSite pageCover={beginTransition} PAGE_COVER_DELAY={ANIMATION_TRANSITION_DELAY}/>} />
		</Routes>
	</>;
}

