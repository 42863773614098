import MangoSiteLogic from "./MangoSiteLogic";

import './MangoSite.scss';
import Slider from "./components/slider/Slider";
import { useState } from "react";

const MangoSite = ({pageCover, PAGE_COVER_DELAY}) => {

    const {  } = MangoSiteLogic({});

    const [ backgroundClass, SetBackgroundClass ] = useState('');

    const handleBackgroundColorChange = id => {
        SetBackgroundClass(id === 0 ? 'red' : id === 2 ? 'brown' : '' );
    };
    
    return (
        <div className="mango-site w-full min-h-[520px] flex justify-center">
            <div className={`bg w-full min-h-[520px] -z-10 absolute`}></div>
            <div className={`bg red w-full min-h-[520px] -z-10 absolute ${backgroundClass === 'red' ? 'show' : ''}`}></div>
            <div className={`bg brown w-full min-h-[520px] -z-10 absolute ${backgroundClass === 'brown' ? 'show' : ''}`}></div>
            <div className="w-[100vw] lg:w-[1440px]">
                <Slider pageCover={pageCover} PAGE_COVER_DELAY={PAGE_COVER_DELAY} handleSelectedSlide={handleBackgroundColorChange} />
            </div>
        </div>
    );
};

export default MangoSite;