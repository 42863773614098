import useFormattingFunctions from "src/helpers/useFormattingFunctions";

const MyProject = ({ props }) => {
	const { formatDate } = useFormattingFunctions();

	const data = {
		title: "My project",
		client: "John Doe",
		status: "On-going",
		mileStones: [
			{
				name: "Flowchart",
				dueDate: new Date(),
				link: "https://davidchavez.tech",
				status: "Finished",
				finishedDate: new Date(),
			},
			{
				name: "Design",
				dueDate: new Date(),
				link: "https://davidchavez.tech",
				status: "Approved",
				finishedDate: new Date(),
			},
			{
				name: "Web development",
				dueDate: new Date(),
				link: "",
				status: "In development",
				finishedDate: "",
			},
			{
				name: "Mobile development",
				dueDate: new Date(),
				link: "",
				status: "",
				finishedDate: "",
			},
			{
				name: "Mobile development",
				dueDate: new Date(),
				link: "",
				status: "",
				finishedDate: "",
			},
		],
	};

	return (
		<div className="mt-10 pl-10">
			<span className="text-sm">Welcome back, {data.client}</span>
			<div className="flex gap-x-3 items-baseline mb-4">
				<span className="text-4xl">{data.title}</span>
				<div className="rounded-full size-6 bg-green-500 " />
				<span className="text-gray-600 pt-1 self-center">{data.status}</span>
			</div>
			<div className="underline text-xl text-gray-500 mb-2">
				Milestones
			</div>
			{data.mileStones.map((milestone, index) => (
				<div key={index} className="flex mb-2 gap-x-3 items-center">
					<span className="text-sm text-gray-300">
						{milestone.name}
					</span>
					{milestone.status && (
						<span
							className={`text-xs text-white rounded-full px-2 font-semibold py-[2px] ${
								milestone.status === "In development"
									? "bg-green-500 text-gray-800"
									: "bg-gray-500"
							}`}
						>
							{milestone.status}
						</span>
					)}

					<div className="flex flex-col">
						{!milestone.finishedDate && (
							<span className="font-semibold text-xs text-gray-400">
								Scheduled for
							</span>
						)}
						<span className="text-gray-600 text-sm">
							{formatDate(
								milestone.finishedDate || milestone.dueDate
							)}
						</span>
					</div>
					{milestone.link && (
						<a
							href={milestone.link}
							className="text-yellow-600 text-sm"
							target="_blank"
							rel="noreferrer"
						>
							(View)
						</a>
					)}
				</div>
			))}
		</div>
	);
};

export default MyProject;
