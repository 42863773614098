const useFormattingFunctions = () => {

    const formatJSDateObjToHoursAmPm = (dateObj, format) => {
        if(typeof dateObj !== 'object') dateObj = new Date(dateObj);

        const hours = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
        const minutes = dateObj.getMinutes() < 10 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();
        const amPm = dateObj.getHours() < 12 ? 'am' : 'pm';

        if(format === 'afternoon-morning') {
            return `${hours}:${minutes} ${amPm === 'am' ? 'de la mañana' : 'de la tarde'}`;
        } else return `${hours}:${minutes}${amPm}`;
    }

    const capitalizeFirstLetter = (word) => {
        const firstLetter = word.charAt(0);
        const firstLetterCapitalized = firstLetter.toUpperCase();
        const restOfWord = word.slice(1);
        return firstLetterCapitalized + restOfWord;
    }

	const formatDate = (date, format, reformat) => {
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"Jun",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const dayName = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		if (typeof date === "string" && reformat)
			date = date.replaceAll("-", "/");
		if (typeof date !== "object") date = new Date(date);
		if (typeof date !== "object") return "--/--/--";

		const dayOfTheWeek = date.getDay();
		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();

		if (format === "written-out") {
			return `${day} de ${months[month]}, ${year}`;
		} else if (format === "date-and-time") {
			return `${day}-${month + 1}-${year} ${formatJSDateObjToHoursAmPm(
				date
			)}`;
		} else if (format === "written-out-and-time") {
			return `${day} de ${
				months[month]
			}, ${year} ${formatJSDateObjToHoursAmPm(date)}`;
		} else if (format === "time-date") {
			return `${formatJSDateObjToHoursAmPm(date)} - ${day}/${
				month + 1
			}/${year}`;
		} else if (format === "day-month") {
			return `${day} ${months[month]}`;
		} else if (format === "day-and-month") {
			return `${day} de ${months[month]}`;
		} else if (format === "month year") {
			return `${months[month]} ${year}`;
		} else if (format === "Month year") {
			return `${capitalizeFirstLetter(months[month])} ${year}`;
		} else if (format === "week-day-and-date") {
			return `${dayName[dayOfTheWeek]} ${day} de ${months[month]}`;
		} else if (format === "dashed") {
			return `${year}-${month + 1}/${day}`;
		} else {
			return `${month + 1}/${day}/${year}`;
		}
	};

    return {
        formatDate
    };
};

export default useFormattingFunctions;
