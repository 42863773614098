
import { useEffect, useRef, useState } from 'react';
import './SugarCaneSlide.scss';

const SugarCaneSlide = ({pageCover, PAGE_COVER_DELAY, id, slidesPositions, handleSlideChange}) => {

    const BACKGROUND_DELAY = 400;
    
    const [ showBackground, SetShowBackground ] = useState('');
    const [ showBottle, SetShowBottle ] = useState('');

    const hasLoaded_ref = useRef(false);

    useEffect(() => {
        if(pageCover) return;
        if (!hasLoaded_ref.current) {
            setTimeout(() => {
                hasLoaded_ref.current = true;
                SetShowBottle('show-bottle');
                if(slidesPositions[id] === 'center') SetShowBackground('show-background');
            }, PAGE_COVER_DELAY / 2);
        } else if (slidesPositions[id] === 'center') {
            setTimeout(() => SetShowBackground('show-background'), BACKGROUND_DELAY);
        } else SetShowBackground('');
        
    }, [pageCover, slidesPositions]);


    return (
        <div className={`slide cursor-pointer flex justify-center items-center w-[600px] ${slidesPositions[id]}`} onClick={() => handleSlideChange(id)} >
            <img className={`paint-brown-right ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-brown-right.png" alt="" />
            <img className={`paint-brown-left ${showBackground} absolute z-[-1]`} src="images/pages/juise-raw/paint-brown-left.png" alt="" />
            <img className={`sugar-cane-plant absolute z-[-1] slide-up ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/sugar-cane-plant.png" alt="" />
            <img className={`pure-cane-filled max-w-[90vw] absolute z-[-1] slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/pure-cane-filled.svg" alt="" />
            <img className={`two-sugar-canes absolute z-[-1] rotate-in-to-right ${showBackground}`} style={{'--delay': '0s', '--rotation': '0deg'}} src="images/pages/juise-raw/two-sugar-canes.png" alt="" />
            <img className={`many-sugar-canes absolute z-[-1] slide-down ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/many-sugar-canes.png" alt="" />
            <img className={`bottle zoom-out ${showBottle} ${showBackground}`} style={{'--delay': '0s'}} src="images/pages/juise-raw/brown-bottle.png" alt="" />
            <img className={`pure-cane-empty max-w-[90vw] absolute slide-up ${showBackground}`} style={{'--delay': '.1s'}} src="images/pages/juise-raw/pure-cane-empty.svg" alt="" />
        </div>
    )
};

export default SugarCaneSlide;
