import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GlobalsContext = createContext();

export function GlobalContextProvider(props) {

    const MOBILE_MAX_WIDTH = 768;
    const ANIMATION_TRANSITION_DELAY = 800;
    const PARAMS_WITHOUT_TRANSITION_ANIMATION = [
        'show-contact-me-modal'
	];

    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const documentLoaded_ref = useRef(false);
    const [ beginTransition, SetBeginTransition ] = useState(true);
    const [ isMobile, SetIsMobile ] = useState(document.querySelector('body').clientWidth < MOBILE_MAX_WIDTH);

    const navigateTo = (param, value, newPath) => {
        const path = location.pathname;
        const params = new URLSearchParams(location.search);

        if(!PARAMS_WITHOUT_TRANSITION_ANIMATION.includes(param)) SetBeginTransition(true);

        setTimeout(() => {
            if(!param) {
                params.forEach((_, key) => {
                    params.delete(key);
                });
                navigate(`${newPath || path}?${params.toString()}`);
            } else {
                params.set(param, value);
                navigate(`${newPath || path}?${params.toString()}`);
            }
        }, PARAMS_WITHOUT_TRANSITION_ANIMATION.includes(param) ? 0 : ANIMATION_TRANSITION_DELAY);
    };

    const getQueryParameter = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    }

    useEffect(() => {
        setTimeout(() => {
            SetBeginTransition(false);
        }, ANIMATION_TRANSITION_DELAY);
    }, [currentPath])
console.log(beginTransition)
    useEffect(() => {
        const handleWindowResize = (e) => {
            const body = document.querySelector('body');
            const bodyWidth = body.clientWidth;

            SetIsMobile(bodyWidth < MOBILE_MAX_WIDTH);
        };

        // setTimeout(() => SetBeginTransition(false), ANIMATION_TRANSITION_DELAY)
        // SetBeginTransition(false);

        window.addEventListener('resize', handleWindowResize);

        documentLoaded_ref.current = true;

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    const value = {
        isMobile,
        navigateTo,
        getQueryParameter,
        documentLoaded_ref,
        navigate,
        beginTransition,
        ANIMATION_TRANSITION_DELAY
    };
    
    return <GlobalsContext.Provider value={value} {...props} />;
}

export function useGlobalContext() {
    const context = useContext(GlobalsContext);
    if(!context) {
        throw new Error('Redirect context must be inside of RedirectContext provider');
    }
    return context;
}